import http from "@/util/http";

// 查询所有图片
export function getPhotoList(param) {
  return http.post(`api/common/page`, param);
}
// 保存图片
export function savePhotoList(param) {
  return http.post(`api/common/save`, param);
}
