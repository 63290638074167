/** **   http.js   ****/
// 导入封装好的axios实例
import request from "./request";

const http = {
  /**
   * methods: 请求
   * @param url 请求地址
   * @param params 请求参数
   */
  get(url, params) {
    const config = {
      method: "get",
      headers: { "Content-Type": "application/json" },
      url: url,
    };
    if (params) config.params = params;
    return request(config);
  },
  post(url, params) {
    const config = {
      method: "post",
      headers: { "Content-Type": "application/json" },
      url: url,
    };
    if (params) config.data = params;
    return request(config);
  },
  put(url, params) {
    const config = {
      method: "put",
      headers: { "Content-Type": "application/json" },
      url: url,
    };
    if (params) config.params = params;
    return request(config);
  },
  delete(url, params) {
    const config = {
      method: "delete",
      headers: { "Content-Type": "application/json" },
      url: url,
    };
    if (params) config.params = params;
    return request(config);
  },

  uploadImg(url, img) {
    var formData = new FormData();
    const boundary = "--------------------------" + Date.now().toString();

    formData.append("file", img, img.name);
    let header = {
      "Content-Type": "multipart/form-data;boundary=" + boundary,
      Accept: "application/json",
    };
    const config = {
      url: url,
      headers: header,
      method: "post",
    };
    if (img) config.data = formData;
    return request(config);
  },
};
// 导出
export default http;
