<script setup>
import { nextTick, ref } from "vue";
import { ElInput, ElNotification } from "element-plus";
import { Search, Upload } from "@element-plus/icons-vue";
import { getPhotoList, savePhotoList } from "./api/file";
const tag = ref("");
const city = ref("");
const form = ref({});
const formLabelWidth = "140px";
const dialogFormVisible = ref(false);

const inputValue = ref("");
const dynamicTags = ref([]);
const inputVisible = ref(false);
const InputRef = ref();
// 上传的文件列表
const fileList = ref([]);

const handleClose = (tag) => {
  dynamicTags.value.splice(dynamicTags.value.indexOf(tag), 1);
};

const showInput = () => {
  inputVisible.value = true;
  nextTick(() => {
    InputRef.value.input.focus();
  });
};
const onSubmit = () => {
  const fileLabel = ref({});
  console.log("提交");
  fileLabel.value.label = form.value.label;
  const list = fileList.value;
  fileLabel.value.fileId = list[0].id;
  fileLabel.value.additional = form.value.additional;
  fileLabel.value.description = form.value.description;
  fileLabel.value.password = form.value.password;
  const tagList = dynamicTags.value;
  for (let i = 0; i < tagList.length; i++) {
    if (i === 0) {
      fileLabel.value.additional = tagList[i];
    } else {
      fileLabel.value.additional =
        fileLabel.value.additional + " " + tagList[i];
    }
  }
  savePhotoList(fileLabel.value)
    .then(({ data: res }) => {
      if (res.code === "200") {
        ElNotification({
          title: "Success",
          message: "保存成功",
          type: "success",
        });
        get();
        cancel();
      }
    })
    .catch((err) => {
      ElNotification({
        title: "Error",
        message: "保存失败" + err.msg,
        type: "error",
      });
    });
};

const cancel = () => {
  console.log("取消");
  dialogFormVisible.value = false;
  fileList.value = [];
  form.value = {};
};

const handleInputConfirm = () => {
  if (inputValue.value) {
    dynamicTags.value.push(inputValue.value);
  }
  inputVisible.value = false;
  inputValue.value = "";
};

const handleRemove = (file) => {
  console.log("删除的文件");
  console.log(file);
};
const handleExceed = (files, fileList) => {
  console.log("超出文件上限");
  console.log(files);
  console.log(fileList);
};
const fileSuccess = (response) => {
  console.info("上传完成");
  console.info(response.data);
  fileList.value.push(response.data);
};

const request = ref({
  totalRows: 10,
  page: 1,
  rows: 30,
  filter: {},
  sort: {},
});
const handleCurrentChange = (page) => {
  // loading.value = true;
  request.value.page = page;
  get();
};
const query = () => {
  if (tag.value.length > 0) {
    request.value.filter.tag = tag.value;
  }
  if (city.value.length > 0) {
    request.value.filter.city = city.value;
  }
  get();
};
const photoList = ref([]);
const get = () => {
  getPhotoList(request.value)
    .then(({ data: res }) => {
      if (res.code === "200") {
        if (res.data !== null) {
          res.data.forEach((item) => {
            item.tag = item.additional.split(" ");
          });
        }
        request.value.filter = {};
        request.value.totalRows = res.totalRows;
        photoList.value = res.data;
      }
    })
    .catch((err) => {
      ElNotification({
        title: "Error",
        message: "查询失败" + err.msg,
        type: "error",
      });
    });
};

get();
</script>
<template>
  <div class="common-layout">
    <el-container>
      <el-header>
        <el-col :xs="0" :sm="1" :md="1" :lg="1" :xl="1">
          <div class="grid-content ep-bg-purple" />
        </el-col>
        <el-col :xs="24" :sm="22" :md="22" :lg="22" :xl="22">
          <div id="title">
            <el-input
              v-model="tag"
              style="width: 240px; margin-right: 10px"
              placeholder="请输入标签"
            />
            <el-input
              v-model="city"
              style="width: 240px; margin-right: 10px"
              placeholder="请输入地级市"
            />
            <el-button type="primary" :icon="Search" @click="query"
              >搜索</el-button
            >
            <el-button
              type="primary"
              :icon="Upload"
              @click="dialogFormVisible = true"
              >上传</el-button
            >
          </div>
        </el-col>
        <el-col :xs="0" :sm="1" :md="1" :lg="1" :xl="1">
          <div class="grid-content ep-bg-purple" />
        </el-col>
      </el-header>
      <el-main>
        <el-row :gutter="0">
          <el-col :xs="0" :sm="1" :md="1" :lg="1" :xl="1">
            <div class="grid-content ep-bg-purple" />
          </el-col>
          <el-col :xs="24" :sm="22" :md="22" :lg="22" :xl="22">
            <div class="flex flex-wrap gap-4 photo-list">
              <div class="photo-box" v-for="item in photoList" :key="item.id">
                <el-image
                  class="full-size"
                  :src="item.fileUrl"
                  :preview-src-list="[item.fileUrl]"
                  :initial-index="1"
                  fit="cover"
                >
                </el-image>
                <div class="tag-box">
                  <el-tag type="primary" style="margin-right: 5px">{{
                    item.city
                  }}</el-tag>
                  <el-tag
                    v-for="(t, k) in item.tag"
                    :key="k"
                    type="success"
                    style="margin-right: 5px"
                    >{{ t }}</el-tag
                  >
                </div>
              </div>
            </div>
          </el-col>
          <el-col :xs="0" :sm="1" :md="1" :lg="1" :xl="1">
            <div class="grid-content ep-bg-purple" />
          </el-col>
        </el-row>
      </el-main>

      <!--      弹窗-->
      <div id="page-box">
        <el-pagination
          size="small"
          background
          layout="prev, pager, next"
          :page-size="request.rows"
          :total="request.totalRows"
          class="mt-4"
          @current-change="handleCurrentChange"
        />
      </div>
    </el-container>
  </div>

  <el-dialog v-model="dialogFormVisible" title="上传文件" width="500">
    <el-form :model="form">
      <el-form-item label="请输入上传密码" :label-width="formLabelWidth">
        <el-input v-model="form.password" autocomplete="off" />
      </el-form-item>
      <el-form-item label="类型" :label-width="formLabelWidth">
        <el-select v-model="form.label" placeholder="请选择文件类型">
          <el-option label="旅游" value="1" />
          <el-option label="web" value="2" />
          <el-option label="工作" value="3" />
          <el-option label="家庭" value="4" />
        </el-select>
      </el-form-item>
      <el-form-item label="请输入地级市" :label-width="formLabelWidth">
        <el-input v-model="form.city" autocomplete="off" />
      </el-form-item>
      <el-form-item label="标签" :label-width="formLabelWidth">
        <div class="flex gap-2">
          <el-tag
            v-for="tag in dynamicTags"
            :key="tag"
            closable
            :disable-transitions="false"
            @close="handleClose(tag)"
            style="margin-right: 3px"
          >
            {{ tag }}
          </el-tag>
          <el-input
            v-if="inputVisible"
            ref="InputRef"
            v-model="inputValue"
            class="w-20"
            size="small"
            @keyup.enter="handleInputConfirm"
            @blur="handleInputConfirm"
          />
          <el-button
            v-else
            class="button-new-tag"
            size="small"
            @click="showInput"
          >
            + 新标签
          </el-button>
        </div>
      </el-form-item>
      <el-form-item label="描述" :label-width="formLabelWidth">
        <el-input
          v-model="form.description"
          style="width: 240px"
          :autosize="{ minRows: 2, maxRows: 4 }"
          type="textarea"
          placeholder="请输入"
        />
      </el-form-item>
      <el-form-item label="上传图片" :label-width="formLabelWidth">
        <el-upload
          class="upload-demo"
          action="/api/common/tour/upload"
          :on-remove="handleRemove"
          multiple
          :limit="10"
          :on-exceed="handleExceed"
          :on-success="fileSuccess"
          :file-list="fileList"
        >
          <el-tooltip
            content=".emf、.wmf、.pict、.jpeg、.png、.dib、.gif、.tiff、.eps、.bmp、.wpg、.jpg"
            placement="top"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-tooltip>
        </el-upload>
      </el-form-item>
      <el-form-item :label-width="formLabelWidth">
        <el-button type="primary" @click="onSubmit">确认</el-button>
        <el-button @click="cancel">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<style lang="less">
html {
  background: linear-gradient(
    to bottom,
    rgb(135, 206, 235),
    rgb(255, 255, 255)
  );
}
body,
html,
#app {
  font-size: 1rem;
  height: 100%;
  width: 100%;
  margin: 0;
}

/* 滚动条样式 */
/* 滚动条的整体样式 */
/* 用于设置滚动条的整体样式
在这里设置宽高，以控制滚动条尺寸，且必须要设置宽高，否则不生效
宽高分别对应 y轴 和 x轴 的滚动条尺寸
若宽高为0，则可隐藏滚动条，但仍可保持滚动 */
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
  height: 6px;
}
/* 滚动条内的轨道 */
/* 滚动条轨道
不设置则不出现轨道 */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
  border-radius: 0;
}
/* 滚动条内的滑块 */
/* 滚动条滑块，即滚动条滚动的部分
必须要设置，否则不会出现滑块 */
::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
  background: rgba(137, 207, 240, 1);
  transition: color 0.2s ease;
}
/* X轴滚动条和Y轴滚动条的交接处
不设置，默认为白色小方块，宽高随X轴和Y轴滚动条尺寸 */
/* ::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0.1);
} */
/* ::-webkit-scrollbar-track-piece
没有滑块的滚动条轨道，或者说是内层轨道
同滚动条轨道 */
/* ::-webkit-scrollbar-button
滚动条两端的箭头按钮
不设置则不出现 */

#title {
  text-align: center;
  line-height: 50px;
}
.photo-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

#page-box {
  height: 50px;
  text-align: center;
  display: flex;
  padding-right: 10px;
  justify-content: center;
}

.full-size {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.tag-box {
  position: relative; /* 绝对定位以覆盖图片 */
  bottom: 35px; /* 根据需要调整位置 */
  left: 10px; /* 根据需要调整位置 */
  z-index: 10; /* 确保标签在上面 */
}

.photo-box {
  max-height: 300px;
  max-width: 385px;
  margin-top: 10px;
}
</style>
